import React, { useEffect, useState, useRef } from "react";
import Uploadd from "./components/Upload";
import { Buffer } from "buffer";
import AWS from "aws-sdk";
import Form from "./Form";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { S3Client, S3 } from "@aws-sdk/client-s3";
import { XhrHttpHandler } from "@aws-sdk/xhr-http-handler";
import { Upload } from "@aws-sdk/lib-storage";

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;
const eventTypeList = [
  "Bhandara",
  "Safai Abhiyan",
  "Blood Donation",
  "Tree Plantation",
  "Body Donation",
];
const initialState = {
  eventTypeError: "",
  districtNameError: "",
  eventDateError: "",
  eventTimeError: "",
  eventNameError: "",
  filesError: "",
  eventName: "",
  eventType: "",
  districtName: "",
  tehsilName: "",
  villageName: "",
  eventDate: "",
  eventTime: "",
  name: "",
  mobileNumber: "",
  countryName: "",
  nameError: "",
  mobileNumberError: "",
  countryNameError: "",
};
function App() {
  const [files, setFiles] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [formData, setFormData] = useState({ ...initialState });
  const percentageRef = useRef(null);
  const requiredFields = [
    "eventName",
    "eventType",
    "districtName",
    "eventDate",
    "eventTime",
    "name",
    "mobileNumber",
    "countryName",
  ];

  const S3_BUCKET = "msg-event-data";
  const REGION = "ap-south-1";
  const mobileNumberLimit = 12;
  AWS.config.update({
    accessKeyId: "AKIATYISOMC63X57WHEZ",
    secretAccessKey: "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",
    signatureVersion: "v4",
    region: REGION,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const maxDate = new Date().toISOString().split("T")[0];
  const getValidPath = () => {
    const { districtName, tehsilName, villageName, countryName } = formData;
    let str = "";
    str += villageName ? `${villageName}` : str;
    str += tehsilName ? `_${tehsilName}` : str;
    str += districtName ? `_${districtName?.trim()}` : str;
    str += countryName ? `_${countryName?.trim()}` : str;
    return str;
  };
  const clearState = () => {
    setFormData({ ...initialState });
    setShowLoader(false);
    setPercentage(0);
    toast.success("Files are uploaded successffully");
  };
  const formatTime = (time:any) => {
    if(time){
      const ft = time?.trim().split(":");
      return `h${ft[0]}-t${ft[1]}`;
    }
  }

  const uploadImageToS3 = async (file: any, greatestSize: any) => {
    // @ts-ignore: Unreachable code error
    const { eventName, eventType, eventDate, eventTime, name, mobileNumber, villageName, tehsilName, districtName } = formData;
    const params = {
      Body: file,
      Key: `${eventType}_${eventName?.trim()}/${getValidPath()}/${name}_${mobileNumber}_${eventDate}_${formatTime(eventTime)}/${file?.name}`,
      Bucket: S3_BUCKET,
      ContentType: file.type,
    };
    const target = { ...params };
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({
          region: REGION,
          credentials: {
            accessKeyId: "AKIATYISOMC63X57WHEZ",
            secretAccessKey: "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",
          },
        }),
        queueSize: 4, // optional concurrency configuration
        leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      });
      setShowLoader(true);
      parallelUploads3.on("httpUploadProgress", (progress: any) => {
        // @ts-ignore: Unreachable code errors
        const percentage = Math.round((progress.loaded / progress.total) * 100);
        if (file.size === greatestSize) {
          setPercentage(percentage);
          percentage === 100 && clearState();
        }
      });
      await parallelUploads3.done();
    } catch (err) {
      console.log(err, "errorrorr");
    }
  };
  const validateFormData = (files: any) => {
    requiredFields.forEach((field) => {
      // @ts-ignore: Unreachable code errors
      if (!formData[field]) {
        // @ts-ignore: Unreachable code errors
        setFormData((formData) => ({
          ...formData,
          [`${field}Error`]: "This Field is required",
        }));
      }
    });
    if (!files?.length) {
      setFormData((formData) => ({
        ...formData,
        [`filesError`]: "Please select file to upload",
      }));
    }
    // @ts-ignore: Unreachable code errors
    if (formData["eventType"] === "Select Event Type") {
      setFormData({
        ...formData,
        [`eventTypeError`]: "Please select a valid option",
      });
    }
    // @ts-ignore: Unreachable code errors
    return requiredFields.every((fld) => formData[`${fld}Error`] === "");
  };
  const handleUpload = (event: any) => {
    event.preventDefault();

    // @ts-ignore: Unreachable code error
    if (validateFormData(files) && files?.length) {
      var greatestSize = files.reduce(
        // @ts-ignore: Unreachable code error
        (cur, prev) => (cur < prev?.file?.size ? prev?.file?.size : cur),
        0
      );
      for (const file in files) {
        // @ts-ignore: Unreachable code error
        uploadImageToS3(files[file].file, greatestSize);
      }
      setFiles([]);
    }
    files?.length &&
      setFormData((formData) => ({ ...formData, filesError: "" }));
  };

  const disableUploadButton = () => {
    return requiredFields?.every(
      (field: String) =>
      // @ts-ignore: Unreachable code error
        !!formData[field] && formData["eventType"] !== "Select Event Type"
    );
  };

  const updateFormData = (e: any) => {
    const { name, value } = e.target;
    if (name === "eventType" && value === "Select Event Type") {
      return;
    }
    setFormData((formData) => ({
      ...formData,
      [name]: value,
      [`${name}Error`]: "",
    }));
    disableUploadButton();
  };

  useEffect(() => {
    setFormData((formData) => ({ ...formData, filesError: "" }));
  }, [files]);

  const handleNumChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value.slice(0, mobileNumberLimit),
      [`${name}Error`]: "",
    }));
  };

  return (
    <div>
      <div className="container py-3">
        <div className="row">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <form className={showLoader ? "disable-uplaod" : ""}>
                <div className="form-group">
                  <h3 className="form-heading">Upload Welfare Data</h3>
                </div>
                <div className="form-group mb">
                  <label className="required">Event Type</label>
                  <select
                    className="form-control"
                    name="eventType"
                    value={formData.eventType}
                    onChange={(e) => updateFormData(e)}
                  >
                    <option>Select Event Type</option>
                    {eventTypeList?.map((str: string) => (
                      <option>{str}</option>
                    ))}
                    <option>Other</option>
                  </select>
                  <p className="error-text">{formData?.eventTypeError}</p>
                </div>
                <div className="form-group mb">
                  <label className="required">Event Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="event-name"
                    placeholder="Enter Event Name"
                    name="eventName"
                    value={formData.eventName}
                    maxLength={25}
                    onChange={(e) => updateFormData(e)}
                  />
                  <p className="error-text">{formData?.eventNameError}</p>
                </div>
                <div className="form-group mb">
                  <label className="required">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter Your Name"
                    name="name"
                    value={formData.name}
                    maxLength={25}
                    onChange={(e) => updateFormData(e)}
                  />
                  <p className="error-text">{formData?.nameError}</p>
                </div>
                <div className="form-group mb">
                  <label className="required">Mobile Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="mobileNumber"
                    placeholder="Enter Mobile Number"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={(e) => handleNumChange(e)}
                  />
                  <p className="error-text">{formData?.mobileNumberError}</p>
                </div>
                <div className="form-group mb">
                  <label>Village</label>
                  <input
                    type="text"
                    className="form-control"
                    id="village-name"
                    placeholder="Enter Village Name"
                    name="villageName"
                    value={formData.villageName}
                    maxLength={25}
                    onChange={(e) => updateFormData(e)}
                  />
                </div>
                <div className="form-group mb">
                  <label>Tehsil</label>
                  <input
                    type="text"
                    className="form-control"
                    id="tehsil-name"
                    placeholder="Enter Tehsil Name"
                    name="tehsilName"
                    value={formData.tehsilName}
                    maxLength={25}
                    onChange={(e) => updateFormData(e)}
                  />
                </div>
                <div className="form-group mb">
                  <label className="required">District</label>
                  <input
                    type="text"
                    className="form-control"
                    id="district-name"
                    placeholder="Enter District Name"
                    name="districtName"
                    value={formData.districtName}
                    maxLength={25}
                    onChange={(e) => updateFormData(e)}
                  />
                  <p className="error-text">{formData?.districtNameError}</p>
                </div>
                <div className="form-group mb">
                  <label className="required">Country</label>
                  <input
                    type="text"
                    className="form-control"
                    id="country-name"
                    placeholder="Enter Country Name"
                    name="countryName"
                    value={formData.countryName}
                    maxLength={12}
                    onChange={(e) => updateFormData(e)}
                  />
                  <p className="error-text">{formData?.countryNameError}</p>
                </div>
                <div className="form-group mb">
                  <label className="required">Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="event-date"
                    placeholder="Enter Event Date"
                    name="eventDate"
                    value={formData.eventDate}
                    max={maxDate}
                    onChange={(e) => updateFormData(e)}
                  />
                  <p className="error-text">{formData?.eventDateError}</p>
                </div>
                <div className="form-group mb">
                  <label className="required">Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="event-time"
                    placeholder="Enter Event Time"
                    value={formData.eventTime}
                    name="eventTime"
                    onChange={(e) => updateFormData(e)}
                  />
                  <p className="error-text">{formData?.eventTimeError}</p>
                </div>
              </form>
              <form onSubmit={handleUpload}>
                {/* <ReCAPTCHA
                sitekey="6LcqHnQkAAAAAM92IXQ5CKEsPKICpuHkiGSJvEDd"
                onChange={onChange}
              /> */}
                {!showLoader && (
                  <div
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={
                      disableUploadButton()
                        ? ""
                        : "Please fill the required fields"
                    }
                  >
                    <div
                      className={disableUploadButton() ? "" : "disable-uplaod"}
                    >
                      <Uploadd
                        accept=".jpg,.png,.jpeg,.svg,.mp4"
                        label="File Uploader"
                        files={files}
                        setFiles={setFiles}
                        multiple
                      />
                    </div>
                  </div>
                )}
                {showLoader && (
                  <div className="loader">
                    <CircularProgressbar
                      value={percentage}
                      text={`${percentage}%`}
                    />
                  </div>
                )}
                <p className="error-text">{formData?.filesError}</p>
                <p className="instructions" style={{ marginTop: 15 }}>
                  {" "}
                  minimise the window till the data is not completely uploaded.
                </p>
                <p className="instructions">
                  If you don’t get the upload confirmation message, please
                  re-upload the data.
                </p>
                <button
                  className={`btn btn-primary ${
                    showLoader ? "disable-uplaod" : ""
                  }`}
                  type="submit"
                >
                  Upload Data
                </button>
                <Tooltip id="my-tooltip" />
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
